<template>
  <section class="main-body-wrapper">
    <div class="container p-0">
      <div class="form-wrapper congrats-content">
        <div class="container-fluid">
          <template>
            <h1>Application Submitted</h1>
            <p>
              We are currently reviewing your application. We'll email you as soon as it's
              approved or if we need additional information.
              <br /><br />
              If you have questions in the meantime, please email us at
              <a :href="`mailto:support@${getFacilitator.domain}`"
                ><br />
                support@{{ getFacilitator.domain }}
              </a>
            </p>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '../config';

export default {
  mounted() {
    this.$store.commit('MARK_STEP_COMPLETED', 4); // Updated from setStepProgress
    localStorage.clear();
    window.addEventListener('popstate', this.preventBackButton);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.preventBackButton);
  },
  methods: {
    preventBackButton(event) {
      event.preventDefault();
      this.$router.replace({ name: 'GetStarted' }).catch(()=>{});
    }
  },
  computed: {
    ...mapGetters(['getFacilitator']),
    continueUrl() {
      return config.CONTINUE_URL(this.$route.params.spProductSlug);
    }
  }
};
</script>

<style scoped lang="scss">
a {
  color: #14D584;
}
</style>
