export function getColorType(color) {
  if (!color) {
    return 'light';
  }
  // Convert the color to RGB values
  const rgb = getRGB(color);

  // Calculate the luminance of the color
  const luminance = calculateLuminance(rgb);

  // Check if the luminance is below a threshold to determine if it's a dark color
  return luminance < 0.5 ? 'dark' : 'light';
}

function getRGB(color) {
  // Remove any leading '#' from the color
  const cleanedColor = color.replace('#', '');

  // Check if the cleanedColor is a three-character hex code
  if (cleanedColor.length === 3) {
    const red = parseInt(cleanedColor[0].repeat(2), 16);
    const green = parseInt(cleanedColor[1].repeat(2), 16);
    const blue = parseInt(cleanedColor[2].repeat(2), 16);
    return { red, green, blue };
  }

  // Convert the color to RGB values
  const red = parseInt(cleanedColor.substring(0, 2), 16);
  const green = parseInt(cleanedColor.substring(2, 4), 16);
  const blue = parseInt(cleanedColor.substring(4, 6), 16);

  return { red, green, blue };
}

function calculateLuminance({ red, green, blue }) {
  // Calculate the relative luminance of the color using the formula
  // L = 0.2126 * R + 0.7152 * G + 0.0722 * B
  return (0.2126 * red + 0.7152 * green + 0.0722 * blue) / 255;
}
