<template>
  <section v-if="identity.type" class="main-body-wrapper" id="company-form">
    <div class="page-title w-100">
      <div class="d-flex align-items-center mb-3">
        <h1 style="flex: 0">Add your business info</h1>
        <span
          style="flex: 0 1 auto"
          v-if="business.ownershipType"
          class="ownership-type-badge"
        >
          {{ formattedOwnershipType }}
        </span>
      </div>
      <span class="description">
        We use this information to verify your business in accordance with bank identity
        and compliance policies.
      </span>
    </div>
    <div class="form-outer-wrap form-container">
      <div class="form-wrapper form-company-info">
        <div class="container-fluid px-0">
          <form @submit.prevent="next">
            <div
              class="d-flex flex-column align-items-stretch m-0 form-content"
              ref="form-content"
              v-scrollbar
            >
              <BaseCustomInput
                v-if="identity.type !== IDENTITY_TYPE.INDIVIDUAL"
                ref="input-legal-name"
                name="legalName"
                label="Business Legal Name"
                data-vv-as="Legal name"
                required
                :error="getError('legalName', 'Legal name')"
                v-model="business.legalName"
                v-validate="modelValidations.business.legalName"
                @blur="partialUpdateIdentity()"
                @keydown.enter.prevent="
                  focusNext('input-legal-name', 'input-business-name')
                "
                tabindex="0"
              />
              <BaseCustomInput
                ref="input-business-name"
                name="dbaName"
                label="Doing Business As (DBA)"
                tooltip="The name under which your business conducts its operations and engages with the public."
                data-vv-as="Doing business as"
                required
                :error="getError('dbaName', 'Doing business as')"
                v-model="business.dbaName"
                v-validate="modelValidations.business.dbaName"
                @blur="partialUpdateIdentity()"
                @keydown.enter.prevent="
                  focusNext('input-business-name', 'input-tin-type')
                "
                tabindex="0"
              />
              <BaseSelect
                ref="input-tin-type"
                required
                name="tinType"
                label="Tin Type"
                data-vv-as="Tin Type"
                hasValidation
                v-model="business.tinType"
                v-validate="modelValidations.business.tinType"
                :options="TIN_TYPE_OPTIONS"
                :error="getError('tinType', 'Tin Type')"
                @blur="partialUpdateIdentity()"
                @switch-focus="focusNext('input-tin-type', 'input-tax-id')"
                tabindex="0"
              />
              <BaseCustomInput
                ref="input-tax-id"
                name="federalTaxId"
                :label="tinTypeLabel"
                :data-vv-as="'TIN'"
                required
                :error="getError('federalTaxId', 'TIN')"
                v-maska="tinTypeMask"
                v-model="business.federalTaxId"
                v-validate="modelValidations.business.federalTaxId"
                @blur="partialUpdateIdentity()"
                @keydown.enter.prevent="focusNext('input-tax-id', 'input-phone-number')"
                tabindex="0"
              />
              <small
                v-if="business.tinType === '2'"
                class="form-text text-muted"
                :style="getError('federalTaxId', 'EIN') ? '' : 'margin-top: -15px'"
              >
                Ensure that the business legal name and Employer Identification Number
                (EIN) provided precisely match IRS documentation (e.g., SS-4 Confirmation
                letter, CP-575, or 147C).
              </small>

              <BasePhoneInput
                ref="input-phone-number"
                label="Phone"
                name="phoneNumber"
                data-vv-as="Phone"
                required
                :error="getError('phoneNumber', 'Phone')"
                v-model="business.phoneNumber"
                v-validate="modelValidations.business.phoneNumber"
                @blur="partialUpdateIdentity()"
                @keydown.enter.prevent="focusNext('input-phone-number', 'input-address')"
                tabindex="0"
              />
              <div class="d-grid grid-columns-2 column-gap-5 w-100">
                <BaseCustomInput
                  ref="input-address"
                  name="line"
                  label="Address"
                  data-vv-as="Address"
                  required
                  :error="getError('line', 'Address')"
                  v-model="business.address.line"
                  v-validate="modelValidations.business.address.line"
                  @blur="partialUpdateIdentity()"
                  @keydown.enter.prevent="focusNext('input-address', 'input-city')"
                  tabindex="0"
                />

                <BaseCustomInput
                  ref="input-city"
                  name="city"
                  label="City"
                  data-vv-as="City"
                  required
                  :error="getError('city', 'City')"
                  v-model="business.address.city"
                  v-validate="modelValidations.business.address.city"
                  @blur="partialUpdateIdentity()"
                  @keydown.enter.prevent="focusNext('input-city', 'input-state')"
                  tabindex="0"
                />

                <BaseSelect
                  ref="input-state"
                  name="state"
                  required
                  label="State"
                  hasValidation
                  :options="states"
                  :error="getError('state', 'State')"
                  v-model="business.address.state"
                  v-validate="modelValidations.business.address.state"
                  @switch-focus="focusNext('input-state', 'input-zip-code')"
                  tabindex="0"
                />

                <BaseCustomInput
                  ref="input-zip-code"
                  name="postalCode"
                  label="Zip code"
                  data-vv-as="Zip code"
                  required
                  :error="getError('postalCode', 'Zip code')"
                  v-maska="'#####'"
                  v-model="business.address.postalCode"
                  v-validate="modelValidations.business.address.postalCode"
                  @blur="partialUpdateIdentity()"
                  @keydown.enter.prevent="
                    focusNext('input-zip-code', 'input-established-date')
                  "
                  tabindex="0"
                />

                <BaseSelect
                  ref="input-country"
                  name="country"
                  label="Country"
                  required
                  disabled
                  :options="[{ value: 'US', label: 'US' }]"
                  v-model="business.address.country"
                  addonRightIcon="fa fa-lock"
                  tabindex="-1"
                />

                <BaseDatePicker
                  ref="input-established-date"
                  class="mb-0"
                  name="businessEstablishedDate"
                  label="Business Established Date"
                  data-vv-as="Business Established Date"
                  value-type="format"
                  required
                  :format="PRETTY_DATE_FORMAT_DP"
                  :not-after="new Date()"
                  :error="
                    getError('businessEstablishedDate', 'Business Established Date')
                  "
                  :showCalendarIcon="false"
                  v-model="business.businessEstablishedDate"
                  v-validate="modelValidations.business.businessEstablishedDate"
                  @blur="partialUpdateIdentity()"
                  @switch-focus="
                    focusNext('input-established-date', 'input-expected-monthly-volume')
                  "
                  tabindex="0"
                />
              </div>
              <BaseCustomInput
                ref="input-expected-monthly-volume"
                name="expectedMonthlyVolume"
                label="Expected Monthly Credit Card Volume"
                data-vv-as="Credit Card Volume"
                required
                :error="getError('expectedMonthlyVolume', 'Credit Card Volume')"
                v-model="business.expectedMonthlyVolume"
                v-validate="modelValidations.business.expectedMonthlyVolume"
                @blur="partialUpdateIdentity()"
                @keydown.enter.prevent="
                  focusNext(
                    'input-expected-monthly-volume',
                    'input-select-merchant-category'
                  )
                "
                tabindex="0"
              />
              <BaseSelect
                ref="input-select-merchant-category"
                name="mccCode"
                required
                label="Merchant Category"
                hasValidation
                data-vv-as="Merchant Category"
                :options="spProductMccCodes"
                :error="getError('mccCode', 'Merchant Category')"
                v-model="business.mccCode"
                v-validate="modelValidations.business.mccCode"
                @switch-focus="
                  focusNext(
                    'input-select-merchant-category',
                    'input-select-website-protocol'
                  )
                "
                tabindex="0"
              />
              <div class="d-flex flex-column gap-3 w-100" style="z-index: 2">
                <label class="input-label">
                  <span>Website Link</span>
                  <i class="required-field float-left" />
                </label>
                <div class="d-flex">
                  <div class="col-3 px-0">
                    <BaseSelect
                      ref="input-select-website-protocol"
                      class="hyper"
                      tabindex="-1"
                      :options="[
                        { value: 'http', label: 'http' },
                        { value: 'https', label: 'https' }
                      ]"
                      v-model="certificate"
                      @switch-focus="
                        focusNext('input-select-website-protocol', 'input-website-link')
                      "
                    />
                  </div>
                  <div class="col-9 px-0">
                    <BaseCustomInput
                      ref="input-website-link"
                      class="web-link"
                      style="min-height: 0"
                      name="websiteUrl"
                      data-vv-as="Website Link"
                      :error="getError('websiteUrl', 'Website Link')"
                      v-model="business.websiteUrl"
                      v-validate="modelValidations.business.websiteUrl"
                      @blur="partialUpdateIdentity()"
                      @keydown.enter.prevent="
                        focusNext('input-website-link', 'submit-button')
                      "
                      tabindex="0"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              ref="submit-button"
              type="submit"
              class="btn continue-button"
              :disabled="isSubmitting"
              tabindex="0"
            >
              {{ isSubmitting ? 'Processing...' : 'Continue' }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  mustHaveStreetName,
  mustHaveStreetNumber,
  validfederalTaxId,
  validPhoneFormat,
  validSSN,
  validUrl,
  validzipcodeRegex
} from '@/helpers/CustomValidations';
import { formatDBDateToPrettyDate, formatPrettyDateToDBDate } from '@/helpers/date';
import { debounce, improvedStructuredClone } from '@/helpers/utils';
import { scrollToElement } from '@/plugins/perfect-scrollbar';
import { mapGetters } from 'vuex';
import {
  IDENTITY_TYPE,
  PRETTY_DATE_FORMAT_DP,
  TIN_TYPE_OPTIONS,
  US_STATES
} from '../helpers/constants';

export default {
  name: 'CompanyForm',
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      states: US_STATES.map(s => ({ label: s, value: s })),
      identity: {},
      business: { address: { line: '' }, country: 'US' },
      certificate: 'https',
      spProductMccCodes: [],
      IDENTITY_TYPE,
      TIN_TYPE_OPTIONS,
      PRETTY_DATE_FORMAT_DP,
      isSubmitting: false,
      loader: null,
      tinTypeMask: ''
    };
  },
  computed: {
    ...mapGetters(['getSaveAndExit']),
    isNotIdentityPublic() {
      return this.identity.type !== IDENTITY_TYPE.GOVERNMENT;
    },
    isNotIdentityIndividual() {
      return !(
        this.identity.type === IDENTITY_TYPE.INDIVIDUAL ||
        this.business.ownershipType === 'SoleProprietorship'
      );
    },
    modelValidations() {
      const validations = {
        business: {
          legalName: { required: this.isNotIdentityIndividual, max: 40 },
          dbaName: { required: true, regex: /^[a-zA-Z0-9 _\-&.',]*$/, max: 40 },
          federalTaxId: { required: true, regex: validfederalTaxId },
          phoneNumber: { required: true, validPhoneFormat: true },
          address: {
            line: {
              required: true,
              mustHaveStreetNumber: true,
              mustHaveStreetName: true,
              max: 40
            },
            city: { required: true, max: 28 },
            state: { required: true },
            postalCode: { required: true, regex: validzipcodeRegex }
          },
          businessEstablishedDate: { required: true },
          expectedMonthlyVolume: {
            required: true,
            decimal: 2,
            max_value: 10000000.0,
            min_value: 0.01
          },
          mccCode: { required: true },
          websiteUrl: { required: true, validUrl: true, max: 112 },
          tinType: { required: !this.isNotIdentityIndividual }
        }
      };
      return validations;
    },
    federalTaxIdLbl() {
      if (this.isNotIdentityIndividual) return 'Employer Identification Number (EIN)';
      else if (this.business.tinType && this.business.tinType !== '3')
        return TIN_TYPE_OPTIONS.find(o => o.value === this.business.tinType).label;
      else return 'EIN/SSN';
    },
    formattedOwnershipType() {
      if (!this.business.ownershipType) return '';
      return this.business.ownershipType
        .replace(/([A-Z])(?=[a-z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim();
    },
    tinTypeLabel() {
      const tinTypeOption = TIN_TYPE_OPTIONS.find(
        option => option?.value === this.business?.tinType
      );
      switch (tinTypeOption?.value) {
        case '1':
          this.tinTypeMask = '##-#######';
          return 'Social Security Number (SSN)';
        case '2':
          this.tinTypeMask = '##-#######';
          return 'Employer Identification Number (EIN)';
        case '3':
          this.tinTypeMask = '#########';
          return 'ITIN';
        default:
          this.tinTypeMask = '#########';
          return 'EIN/SSN';
      }
    }
  },
  async created() {
    this.partialUpdateIdentity = debounce(this.dispatchPartialUpdate, 1500);
    this.loader = this.$loading.show();
    try {
      const response = await this.$axios({
        url: '/onboarding/accounts/identity',
        method: 'GET'
      });
      if (['review', 'approved'].includes(response.data.status)) {
        this.$router
          .replace({ name: 'GetStarted', params: { _normalPush: true } })
          .catch(() => {});
      }
      this.identity = {
        type: response.data.identity?.type,
        business: response.data.identity?.business
      };
      this.business = this.identity.business;
      if (this.business.websiteUrl) {
        const websiteUrl = this.business.websiteUrl.split('://');
        if (websiteUrl[0] == 'http' || websiteUrl[0] == 'https') {
          this.certificate = websiteUrl[0];
          this.business.websiteUrl = websiteUrl[1];
        }
      }
      this.$set(
        this.business.address,
        'line',
        this.business.address.streetNum && this.business.address.streetName
          ? `${this.business.address.streetNum} ${this.business.address.streetName}`
          : ''
      );
      if (!this.business.businessEstablishedDate)
        this.$set(this.business, 'businessEstablishedDate', undefined);
      else {
        const formattedEstablishedDate = formatDBDateToPrettyDate(
          this.business.businessEstablishedDate
        );
        this.$set(
          this.business,
          'businessEstablishedDate',
          formattedEstablishedDate !== 'Invalid DateTime'
            ? formattedEstablishedDate
            : this.business.businessEstablishedDate
        );
      }
      if (!this.business.expectedMonthlyVolume)
        this.$set(this.business, 'expectedMonthlyVolume', undefined);
      this.$set(this.business.address, 'country', 'US');
      this.$store.commit('SET_SM_APPLICATION_ID', response.data._id);
    } catch (e) {
      const errorStatus = e.response.status;
      const errorMessage = e.response.data.message
        ? e.response.data.message
        : 'Server side error, please contact the support team.';
      const logObject = {
        module: 'onboarding',
        error: errorMessage,
        details: {
          page: 'companyForm.asyncMounted',
          endpoint: '/onboarding/accounts/identity'
        }
      };

      this.$store.commit('LOG_ERROR', { errorMessage, errorStatus });
      if (errorStatus === 401) {
        this.$router
          .replace({ name: 'GetStarted', params: { _normalPush: true } })
          .catch(() => {});
      }
    } finally {
      this.loader.hide();
    }
  },
  async mounted() {
    this.$validator.extend(validPhoneFormat.name, validPhoneFormat.obj);
    this.$validator.extend(mustHaveStreetName.name, mustHaveStreetName.obj);
    this.$validator.extend(mustHaveStreetNumber.name, mustHaveStreetNumber.obj);
    this.$validator.extend(validUrl.name, validUrl.obj);
    await this.getSpProductMccCodes();
    this.$store.commit('MARK_STEP_COMPLETED', 1);
    if (this.$route.params.spProductSlug.slice(-5) === '-demo') {
      for (const key in this.$refs) {
        if (key.indexOf('input-') > -1) this.$refs[key]._data.touched = true;
      }
    }
    this.$nextTick(() => {
      let attempts = 0;
      const interval = setInterval(() => {
        if (this.$refs['input-legal-name'] || attempts > 10) {
          if (this.$refs['input-legal-name']) {
            this.$refs['input-legal-name'].setFocus();
          }
          clearInterval(interval);
        }
        attempts++;
      }, 100);
    });
    this.$nextTick(() => {
      if (this.$el && typeof this.$el.querySelectorAll === 'function') {
        const inputs = this.$el.querySelectorAll('input, select, textarea, button');
        if (inputs && inputs.length) {
          inputs.forEach(input => {
            input.addEventListener('focus', this.handleFocus);
          });
        }
      } else {
        console.warn(
          'Unable to select elements: this.$el is not available or querySelectorAll is not a function'
        );
      }
    });
  },
  methods: {
    async focusNext(currentRef, nextRef) {
      const currentInput = this.$refs[currentRef];
      if (currentInput && currentInput.$el) {
        const inputElement = currentInput.$el.querySelector('input') || currentInput.$el;
        const inputName = inputElement.getAttribute('name');

        if (inputName) {
          const isValid = await this.$validator.validate(inputName);

          if (!isValid) {
            return;
          }
        }
      }

      this.$nextTick(() => {
        const refElement = this.$refs[nextRef];
        if (!refElement) {
          return;
        }

        if (refElement.$options && refElement.$options.name === 'BaseDatePicker') {
          refElement.focusDatePickerInput();
          return;
        }
        const targetElement = refElement.$el || refElement;
        const nextFocusableElement = this.findNextFocusableElement(targetElement);
        if (nextFocusableElement) {
          nextFocusableElement.focus();
        }
      });
    },
    findNextFocusableElement(element) {
      if (!element) return null;
      if (['INPUT', 'SELECT', 'BUTTON'].includes(element.tagName)) {
        return element;
      }
      const focusableElements = element?.querySelectorAll(
        'input, select, button, [tabindex]:not([tabindex="-1"])'
      );
      return focusableElements[0] || null;
    },
    scrollToInput(nextElement) {
      scrollToElement(nextElement);
    },
    handleFocus(event) {
      const currentElement = event.target;
      if (currentElement) {
        this.scrollToInput(currentElement);
      }
    },
    getPreviousInput(currentElement) {
      const inputs = this.$el?.querySelectorAll('input, select, textarea, button');
      if (!inputs) return null;
      const inputsArray = Array.from(inputs);
      const currentIndex = inputsArray.indexOf(currentElement);
      return currentIndex > 0 ? inputsArray[currentIndex - 1] : null;
    },
    async next() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;
      try {
        const isValid = await this.$validator.validateAll();
        if (!isValid) {
          this.$toasted.show('There are form errors, please check', {
            type: 'error',
            duration: 3000,
            position: 'top-right'
          });
          return;
        }
        this.partialUpdateIdentity.cancel();
        try {
          const data = this.getDataToUpdate();
          await this.$axios({
            url: '/onboarding/accounts/identity',
            method: 'PUT',
            data
          });
          this.$store.commit('MARK_STEP_COMPLETED', 2);
          await this.$router
            .push({
              name: 'VerifyOwnership',
              query: {
                ...this.$route.query,
                form: this.isNotIdentityPublic ? 'individual' : 'contact'
              },
              params: { _normalPush: true }
            })
            .catch(() => {});
        } catch (e) {
          const errorStatus = e.response.status;
          const errorMessage = e.response.data.message
            ? e.response.data.message
            : 'Server side error, please contact the support team.';
          this.submitted = false;
          const logObject = {
            module: 'onboarding',
            error: errorMessage,
            details: {
              page: 'companyForm.next',
              endpoint: '/onboarding/accounts/identity'
            }
          };

          this.$store.commit('LOG_ERROR', { errorMessage, errorStatus });
        }
      } catch (error) {
        console.error('Navigation failed:', error);
      } finally {
        this.isSubmitting = false;
      }
    },
    setStreetNumAndStreetNameAndDeleteAddress(obj, type = 'individual') {
      if (type === 'business') {
        const splittedAddress = obj.address.line.split(' ');
        delete obj.address.line;
        obj.address.streetNum = splittedAddress[0];
        splittedAddress.shift();
        obj.address.streetName = splittedAddress.join(' ');
        return;
      }
      const splittedAddress = obj.address.split(' ');
      delete obj.address;
      obj.streetNum = splittedAddress[0];
      splittedAddress.shift();
      obj.streetName = splittedAddress.join(' ');
    },
    dispatchPartialUpdate() {
      const data = this.getDataToUpdate();
      if (data.identity.business.tinType === '') {
        delete data.identity.business.tinType;
      }
      this.$axios({
        url: '/onboarding/accounts/identity/autosave',
        method: 'PUT',
        data
      });
    },
    getDataToUpdate() {
      const data = { identity: improvedStructuredClone(this.identity) };

      data.identity.business = improvedStructuredClone(this.business);

      data.identity.business.expectedMonthlyVolume = Number(
        data.identity.business.expectedMonthlyVolume
      );

      data.identity.business.websiteUrl = `${this.certificate}://${data.identity.business.websiteUrl}`;

      this.setStreetNumAndStreetNameAndDeleteAddress(data.identity.business, 'business');

      data.identity.business.federalTaxId = data.identity.business.federalTaxId.replace(
        /-/gi,
        ''
      );

      if (data.identity.business.businessEstablishedDate) {
        data.identity.business.businessEstablishedDate = formatPrettyDateToDBDate(
          data.identity.business.businessEstablishedDate
        );
      }

      return data;
    },
    saveAndExit() {
      this.partialUpdateIdentity.cancel();
      this.dispatchPartialUpdate();
      this.$router
        .push({ name: 'ApplicationSaved', params: { _normalPush: true } })
        .catch(() => {});
    },
    async getSpProductMccCodes() {
      const response = await this.$axios({
        url: `/onboarding/spproducts/${this.$route.params.spProductSlug}/mcc`,
        method: 'GET'
      });
      if (Array.isArray(response.data))
        this.spProductMccCodes = response.data.map(m => ({
          label: `${m.value} - ${m.description}`,
          value: m.value
        }));
    },
    getError(fieldName, fieldLabel) {
      let error = this.errors.first(fieldName)
        ? this.errors.first(fieldName).replace(` ${fieldLabel}`, '')
        : null;
      return error;
    }
  },
  watch: {
    getSaveAndExit(newValue) {
      if (newValue) {
        this.saveAndExit();
      }
    }
  }
};
</script>

<style lang="scss">
#company-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-input__inner,
  .mx-input {
    background-color: transparent !important;
  }

  .page-title {
    text-align: left;
    max-width: 480px;
    width: 100%;
    & > div {
      white-space: nowrap;
    }
  }
  .ownership-type-badge {
    display: inline-block;
    margin-left: 20px;
    padding: 5px 8px;
    font-size: 0.75rem;
    font-weight: 500;
    color: white;
    background-color: #322f35;
    border-radius: 4px;
    line-height: 14px;
    vertical-align: middle;
  }

  .form-container {
    margin-bottom: 0;
  }

  .form-control {
    height: 48px;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #666;
}
</style>
