var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[(_vm.business.address)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"owner-detail d-flex justify-content-between"},[_c('button',{ref:"editButton",staticClass:"btn edit-button",on:{"click":function($event){return _vm.$router.push({ name: 'CompanyForm' })}}},[_c('i',{staticClass:"fas fa-pencil-alt"})])]),_c('div',{staticClass:"d-grid grid-columns-2 gap-4"},[_c('div',{staticStyle:{"grid-column":"span 2"}},[_vm._m(0),_c('span',[_vm._v(_vm._s(_vm.business.legalName))])]),_c('div',[_vm._m(1),_c('span',[_vm._v(_vm._s(_vm.business.dbaName))])]),_c('div',[_vm._m(2),_c('span',[_vm._v(_vm._s(_vm.getOwnershipType(_vm.business.ownershipType)))])]),_c('div',[_vm._m(3),_c('span',[_vm._v(_vm._s(_vm.formatEIN(_vm.business.federalTaxId)))])]),_c('div',[_vm._m(4),_c('span',[_vm._v(_vm._s(_vm.business.phoneNumber))])]),_c('div',{staticStyle:{"grid-column":"span 2"}},[_vm._m(5),_c('span',[_vm._v(_vm._s(_vm.business.websiteUrl))])]),_c('div',[_vm._m(6),_c('span',[_vm._v(_vm._s(`${_vm.business.address.streetNum} ${_vm.business.address.streetName}`))])]),_c('div',[_vm._m(7),_c('span',[_vm._v(_vm._s(_vm.business.address.city))])]),_c('div',[_vm._m(8),_c('span',[_vm._v(_vm._s(_vm.business.address.state))])]),_c('div',[_vm._m(9),_c('span',[_vm._v(_vm._s(_vm.business.address.postalCode))])]),_c('div',[_vm._m(10),_c('span',[_vm._v(_vm._s(_vm.business.address.country || 'US'))])])])]):_vm._e(),_vm._l((_vm.individuals),function(person,index){return (!_vm.ignoreIndividuals)?_c('div',{key:index,staticClass:"d-flex flex-column",attrs:{"id":"add-owner-loop"}},[_c('hr',{staticClass:"my-4",attrs:{"id":"form-border"}}),_c('IndividualData',{attrs:{"individual":person,"type":_vm.identity.type}})],1):_vm._e()})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"businesslegalname"}},[_c('span',[_vm._v("Business legal name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"doingbusinessas"}},[_c('span',[_vm._v("Doing business as")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"type"}},[_c('span',[_vm._v("Ownership Type")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"federalTaxId"}},[_c('span',[_vm._v("EIN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"phonenumber"}},[_c('span',[_vm._v("Phone")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"websitelink"}},[_c('span',[_vm._v("Website")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"streetNum"}},[_c('span',[_vm._v("Address")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"city"}},[_c('span',[_vm._v("City")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"State"}},[_c('span',[_vm._v("State")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"Zip code"}},[_c('span',[_vm._v("Zip code")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"country"}},[_c('span',[_vm._v("Country")])])
}]

export { render, staticRenderFns }