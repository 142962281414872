<template>
  <section v-if="identity.type" class="main-body-wrapper" id="verify-ownership-form">
    <div class="page-title w-100">
      <div class="d-flex align-items-center">
        <h1>
          {{ pageTitle }}
        </h1>
        <span
          v-if="isPrivateEntity && business.ownershipType"
          class="ownership-type-badge"
        >
          {{ formattedOwnershipType }}
        </span>
      </div>
      <h2 class="sub-title">
        {{ pageSubtitle }}
      </h2>
    </div>
    <div class="form-wrapper form-company-info">
      <div class="container-fluid px-0">
        <form @submit.prevent="next">
          <template v-if="isPrivateEntity">
            <template v-if="isReviewMode === true">
              <div
                v-for="(owner, index) in owners"
                :ref="index === 0 ? 'firstOwner' : null"
                :key="index"
                class="owner-review"
                tabindex="0"
                @keydown.enter="next"
                @click="editOwner(index)"
                @keydown.space.prevent="editOwner(index)"
              >
                <div class="owner-info">
                  <div class="owner-percentage">{{ owner.ownershipPercentage }}%</div>
                  <div class="d-flex flex-column">
                    <div class="owner-name">
                      {{ owner.firstName }} {{ owner.lastName }}
                    </div>
                    <div class="owner-label">Owner {{ index + 1 }}</div>
                  </div>
                </div>
                <div v-if="ownerErrors[index]" class="error-icon">
                  <i class="fas fa-exclamation-circle"></i>
                </div>
                <div class="edit-icon">
                  <i class="fas fa-chevron-right"></i>
                </div>
                <div
                  class="remove-icon"
                  @click.stop="removeOwner(index)"
                  @keydown.enter.stop="removeOwner(index)"
                  @keydown.space.prevent.stop="removeOwner(index)"
                  tabindex="-1"
                >
                  <i class="fas fa-trash"></i>
                </div>
              </div>

              <div v-if="totalOwnership > 100" class="mb-3">
                <i class="ownership-msg error-msg"
                  >Total ownership percentage cannot exceed 100%.</i
                >
              </div>
              <div v-else-if="totalOwnership < 75" class="mb-3">
                <i class="ownership-msg error-msg"
                  >Ownership should total 75% or more. Add another owner to continue.</i
                >
              </div>

              <button
                v-if="
                  business.ownershipType !== OWNERSHIP_TYPES.SOLE_PROP ||
                  owners.length === 0
                "
                @click="addNewOwner"
                @keydown.enter="addNewOwner"
                @keydown.space.prevent="addNewOwner"
                type="button"
                class="btn add-owner-button"
                tabindex="0"
              >
                <i class="fas fa-plus"></i> Add Owner
              </button>
            </template>

            <IndividualSubForm
              v-else-if="editingIndex !== null"
              :ownershipType="business.ownershipType"
              :type="identity.type"
              :person="owners[editingIndex]"
              :index="editingIndex"
              @change="handleOwnerChange"
              @onInputBlur="partialUpdateIdentity()"
              @onSubmit="next"
            />
          </template>

          <ContactForm
            v-else
            ref="contactForm"
            :contact="contacts[0]"
            @onInputBlur="partialUpdateIdentity()"
            @onSubmit="next"
          />

          <button
            type="submit"
            class="btn continue-button"
            :disabled="
              isSubmitting ||
              (isReviewMode === true &&
                isPrivateEntity &&
                (totalOwnership > 100 || totalOwnership < 75))
            "
            tabindex="0"
          >
            {{ isSubmitting ? 'Processing...' : 'Continue' }}
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { debounce, improvedStructuredClone } from '@/helpers/utils';
import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';
import ContactForm from '../components/ContactForm';
import IndividualSubForm from '../components/IndividualSubForm';
import {
DB_COMPLETE_DATE_FORMAT,
IDENTITY_TYPE,
OWNERSHIP_TYPES,
PRETTY_DATE_FORMAT
} from '../helpers/constants';

export default {
  name: 'VerifyOwnership',
  components: { IndividualSubForm, ContactForm },
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      OWNERSHIP_TYPES,
      identity: {},
      owners: [],
      contacts: [],
      business: { ownershipType: null },
      totalOwnership: 0,
      IDENTITY_TYPE,
      isReviewMode: true,
      editingIndex: null,
      ownerErrors: {},
      isSubmitting: false,
      isIntentionalNavigation: false,
      loader: null
    };
  },
  computed: {
    ...mapGetters(['getSaveAndExit']),
    isPrivateEntity() {
      return this.identity.type !== IDENTITY_TYPE.GOVERNMENT &&
             this.business.ownershipType !== OWNERSHIP_TYPES.PUBLIC_CORP;
    },
    pageTitle() {
      return this.isPrivateEntity ? 'Verify Ownership' : 'Verify Contact Information';
    },
    pageSubtitle() {
      return this.isPrivateEntity
        ? 'We use this information to verify your identity in accordance with bank identity and compliance policies.'
        : 'Please provide contact information for your organization.';
    },
    ownersLength() {
      return this.owners.length;
    },
    modelValidations() {
      const validations = {};
      if (this.isPrivateEntity) {
        validations.owners = {
          length: { min_value: 1 },
          totalOwnership: { min_value: 76, max_value: 100 }
        };
      }
      return validations;
    },
    formattedOwnershipType() {
      if (!this.business.ownershipType) return '';

      return this.business.ownershipType
        .replace(/([A-Z])(?=[a-z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim();
    }
  },
  async created() {
    this.partialUpdateIdentity = debounce(this.dispatchPartialUpdate, 1500);
    this.loader = this.$loading.show();
    try {
      const response = await this.$axios({
        url: '/onboarding/accounts/identity',
        method: 'GET'
      });
      this.identity = this.transformIdentityData(response.data.identity);
      if (this.identity.owners) {
        this.addNewOwner(this.identity.owners, false);
      }
      if (this.identity.contacts) {
        this.contacts = this.identity.contacts;
      }
      if (this.identity.business) {
        this.business = this.identity.business;
      }
    } catch (e) {
      console.error('Failed to fetch identity data:', e.message);
    } finally {
      this.loader.hide();
    }
  },
  beforeDestroy() {
    // Cancel any pending debounced calls when the component is destroyed
    this.partialUpdateIdentity.cancel();
  },
  mounted() {
    if (this.isReviewMode && this.$refs.firstOwner) {
      this.$refs.firstOwner?.focus();
    }
    this.$nextTick(() => {
      if (this.identity && this.owners.length > 0) {
        this.$validator.validateAll();
      }
      this.$store.commit('MARK_STEP_COMPLETED', 2);
    });
  },
  methods: {
    getError(fieldName) {
      return this.errors && this.errors.first ? this.errors.first(fieldName) : '';
    },
    firstErrorByRule(fieldName, ruleName) {
      return this.errors && typeof this.errors.firstByRule === 'function'
        ? this.errors.firstByRule(fieldName, ruleName)
        : '';
    },
    handleOwnerChange(updatedOwner) {
      if (this.editingIndex !== null) {
        this.$set(this.owners, this.editingIndex, updatedOwner);
      }
    },
    addNewOwner(owner, isNew = true) {
      if (
        this.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP &&
        this.owners.length >= 1
      ) {
        this.showToast('A sole proprietorship cannot have more than one owner.', 'error');
        return;
      }

      if (Array.isArray(owner)) {
        owner.forEach(ownerItem => {
          const ownerClone = improvedStructuredClone(ownerItem);
          this.owners.push({
            ...ownerClone,
            ownershipPercentage: ownerClone.ownershipPercentage || 0
          });
        });
      } else if (owner === null) {
        this.owners.push({ ownershipPercentage: 0 });
      } else {
        const ownerClone = improvedStructuredClone(owner);
        this.owners.push({
          ...ownerClone,
          ownershipPercentage: ownerClone.ownershipPercentage || 0
        });
      }

      if (this.owners.length === 1 || isNew) {
        this.editOwner(this.owners.length - 1);
      }
    },
    editOwner(index) {
      this.editingIndex = index;
      this.isReviewMode = false;
      this.ownerErrors[index] = false;
    },
    removeOwner(index) {
      this.owners.splice(index, 1);
      this.calculateTotalOwnership();
      this.isReviewMode = this.identity.type !== IDENTITY_TYPE.GOVERNMENT;
    },
    calculateTotalOwnership() {
      let totalOwnership = 0;
      this.owners.forEach(owner => {
        if (owner.ownershipPercentage) {
          totalOwnership += Number(owner.ownershipPercentage);
        }
      });
      this.totalOwnership = totalOwnership;
    },
    async dispatchPartialUpdate() {
      if (this.isIntentionalNavigation) {
        return;
      }

      const data = this.getDataToUpdate();
      try {
        await this.$axios({
          url: '/onboarding/accounts/identity/autosave',
          method: 'PUT',
          data
        });
      } catch (e) {
        console.error('Failed to dispatch partial update:', e.message);
      }
    },
    refineAndReturnAddress(obj, type = 'individual') {
      const updatedObj = improvedStructuredClone(obj);

      if (updatedObj.address) {
        const addressLine = updatedObj.address.trim();
        const match = addressLine.match(/^(\d+)\s+(.+)$/);

        if (match) {
          updatedObj.streetNum = match[1];
          updatedObj.streetName = match[2];
        } else {
          updatedObj.streetNum = null;
          updatedObj.streetName = addressLine;
        }
      } else {
        updatedObj.streetNum = null;
        updatedObj.streetName = null;
      }
      delete updatedObj.address;

      return updatedObj;
    },
    getDataToUpdate() {
      const data = { identity: {} };

      if (this.isPrivateEntity) {
        data.identity.owners = this.owners.map(owner => ({
          ...owner,
          ...this.refineAndReturnAddress({ address: owner.address }, this.identity.type),
          ssn: owner.ssn ? owner.ssn.replace(/-/g, '') : null,
          birthDate: this.validateAndFormatDate(owner.birthDate, true),
          dateStarted: this.validateAndFormatDate(owner.dateStarted, true)
        }));
      } else {
        data.identity.contacts = [this.$refs.contactForm.getData()].map(contact => ({
          ...contact,
          ...this.refineAndReturnAddress(
            { address: contact.address },
            this.identity.type
          ),
          ssn: contact.ssn ? contact.ssn.replace(/-/g, '') : null
        }));
      }

      return data;
    },
    validateAndFormatDate(dateString, toDB = false) {
      if (!dateString) {
        return null;
      }

      let date = DateTime.fromFormat(dateString, PRETTY_DATE_FORMAT);

      if (!date.isValid) {
        date = DateTime.fromFormat(dateString, DB_COMPLETE_DATE_FORMAT);
      }

      if (!date.isValid) {
        console.error('❌ Invalid date format:', dateString);
        return null;
      }

      return toDB
        ? date.toFormat(DB_COMPLETE_DATE_FORMAT)
        : date.toFormat(PRETTY_DATE_FORMAT);
    },
    transformIdentityData(data) {
      return {
        ...data,
        business: {
          ...data.business,
          expectedMonthlyVolume: Number(data.business.expectedMonthlyVolume)
        },
        owners: data.owners?.map(owner => {
          const updatedOwner =
            !owner.streetNum && !owner.streetName && owner.address
              ? {
                  ...owner,
                  ...this.refineAndReturnAddress(
                    { address: owner.address },
                    this.identity.type
                  )
                }
              : { ...owner };

          return {
            ...updatedOwner,
            ownershipPercentage: Number(updatedOwner.ownershipPercentage),
            ssn: updatedOwner.ssn
              ? updatedOwner.ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
              : null,
            address:
              updatedOwner.streetNum && updatedOwner.streetName
                ? `${updatedOwner.streetNum} ${updatedOwner.streetName}`
                : '',
            birthDate: this.validateAndFormatDate(updatedOwner.birthDate),
            dateStarted: this.validateAndFormatDate(updatedOwner.dateStarted),
            title: updatedOwner.title || undefined,
            email: updatedOwner.email || undefined
          };
        }),
        contacts: data.contacts?.map(contact => ({
          ...contact,
          ssn: contact.ssn
            ? contact.ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
            : null
        }))
      };
    },
    validateOwners() {
      let isValid = true;

      const validations = {
        firstName: { required: true, max: 15 },
        middleName: { max: 15 },
        lastName: { required: true, max: 15 },
        title: { required: true, max: 40 },
        birthDate: { required: true },
        phoneNumber: { required: true, pattern: /^\+?(\d.*){3,}$/ },
        position: { required: true },
        email: { required: true, email: true, max: 40 },
        address: { required: true, max: 40 },
        city: { required: true, max: 28 },
        state: { required: true },
        postalCode: { required: true, regex: /^[0-9]{5}(-[0-9]{4})?$/ },
        ssn: { required: true, regex: /^\d{3}-\d{2}-\d{4}$/ },
        ownershipPercentage: { between: [1, 100] }
      };

      this.ownerErrors = {};
      const errorOwners = [];
      const detailedErrors = {};

      this.owners.forEach((ogOwner, index) => {
        const owner = this.refineAndReturnAddress(ogOwner, this.identity.type);
        let hasError = false;
        detailedErrors[index] = [];
        Object.keys(validations).forEach(key => {
          const validation = validations[key];
          const value = owner[key] || '';
          if (key === 'address') {
            const addressPresent = owner.streetNum && owner.streetName;
            if (!addressPresent && owner.address) {
              const [streetNum, ...rest] = owner.address.split(' ');
              const streetName = rest.join(' ');
              if (!streetNum || !streetName) {
                hasError = true;
                detailedErrors[index].push(
                  `Address must include a street number and name.`
                );
              }
            } else if (!addressPresent) {
              hasError = true;
              detailedErrors[index].push(`Address is required.`);
            }
          } else if (validation.required && !value) {
            hasError = true;
            detailedErrors[index].push(`${key} is required.`);
          } else if (validation.max && value.length > validation.max) {
            hasError = true;
            detailedErrors[index].push(
              `${key} exceeds maximum length of ${validation.max}.`
            );
          } else if (validation.regex && !validation.regex.test(value)) {
            hasError = true;
            detailedErrors[index].push(`${key} does not match the required format.`);
          } else if (
            validation.between &&
            (value < validation.between[0] || value > validation.between[1])
          ) {
            hasError = true;
            detailedErrors[index].push(
              `${key} must be between ${validation.between[0]} and ${validation.between[1]}.`
            );
          }
        });

        if (!hasError) {
          return;
        }
        this.$set(this.ownerErrors, index, true);
        isValid = false;
        errorOwners.push(index + 1);
      });

      if (!isValid) {
        const errorOwnersText = errorOwners.join(', ');
        this.showToast(
          `Errors found in owner(s): ${errorOwnersText}. Please review and correct.`,
          'error'
        );
        console.error(
          `Validation failed for owner(s): ${errorOwnersText}. Detailed errors:`,
          detailedErrors
        );
      }

      return isValid;
    },
    async validateContact() {
      const { contactForm } = this.$refs;
      const isValid = await contactForm.$validator.validateAll();

      if (!isValid) {
        this.showToast('Please correct the errors in the form.', 'error');
      }

      return isValid;
    },
    showToast(message, type) {
      this.$toasted.show(message, { type, duration: 3000 });
    },
    async next() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;

      try {
        // Cancel any pending autosave
        this.partialUpdateIdentity.cancel();

        if (this.isPrivateEntity) {
          if (this.isReviewMode === true) {
            if (this.totalOwnership > 100) {
              this.showToast('Total ownership percentage cannot exceed 100%.', 'error');
              this.isSubmitting = false;
              return;
            } else if (this.totalOwnership < 75) {
              this.showToast(
                'Ownership should total 75% or more. Add another owner to continue.',
                'error'
              );
              this.isSubmitting = false;
              return;
            }

            if (
              this.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP &&
              this.owners.length !== 1
            ) {
              this.showToast('A sole proprietor must have exactly one owner.', 'error');
              this.ownerErrors = this.owners.reduce((acc, _, index) => {
                acc[index] = true;
                return acc;
              }, {});
              this.isSubmitting = false;
              return;
            }

            const isValid = this.validateOwners();
            if (!isValid) {
              this.isSubmitting = false;
              return;
            }
          }
        } else {
          const isValid = await this.validateContact();
          if (!isValid) {
            this.isSubmitting = false;
            return;
          }
        }

        const isFormValid = await this.$validator.validateAll();
        if (!isFormValid) {
          this.showToast('Please review the form for errors.', 'error');
          this.isSubmitting = false;
          return;
        }

        await this.$axios({
          url: '/onboarding/accounts/identity',
          method: 'PUT',
          data: this.getDataToUpdate()
        });

        // Set the flag to indicate intentional navigation
        this.isIntentionalNavigation = true;

        if (
          this.isReviewMode === true ||
          this.identity.type === IDENTITY_TYPE.GOVERNMENT
        ) {
          await this.$router
            .push({
              name: 'BankForm',
              query: this.$route.query,
              params: { _normalPush: true }
            })
            .catch(() => {});
        } else {
          this.isReviewMode = true;
        }
      } catch (e) {
        console.error('Submission failed:', e);
        this.showToast(
          e.response.data.message || 'Submission failed. Please try again.',
          'error'
        );
        this.isReviewMode = this.identity.type !== IDENTITY_TYPE.GOVERNMENT;
      } finally {
        this.isSubmitting = false;
      }
    },
    saveAndExit() {
      this.partialUpdateIdentity.cancel();
      this.dispatchPartialUpdate();
      this.$router
        .push({ name: 'ApplicationSaved', params: { _normalPush: true } })
        .catch(() => {});
    }
  },
  watch: {
    owners: {
      handler() {
        let totalOwnership = 0;
        if (this.owners && this.owners.length) {
          if (
            this.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP &&
            this.owners.length === 1
          ) {
            this.owners[0].ownershipPercentage = 100;
          }
          this.owners.forEach(p => {
            if (p.ownershipPercentage) totalOwnership += Number(p.ownershipPercentage);
          });
        }
        this.totalOwnership = totalOwnership;
      },
      deep: true
    },
    getSaveAndExit(newValue) {
      if (newValue) {
        this.saveAndExit();
      }
    },
    isReviewMode: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            const timeouts = [];
            for (let i = 0; i < 5; i++) {
              timeouts.push(
                setTimeout(() => {
                  if (this.$refs.firstOwner) {
                    this.$refs.firstOwner?.[0]?.focus();
                  }
                }, i * 200)
              ); // Trigger focus 5 times in a second
            }
            setTimeout(() => {
              timeouts.forEach(timeout => clearTimeout(timeout));
            }, 1000);
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
#verify-ownership-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-title,
  .form-wrapper {
    max-width: 480px;
    margin: 0 auto;

    &.page-title {
      text-align: left;

      .sub-title {
        font-size: 16px;
        margin-top: 5px;
        line-height: 1.5;
      }
    }
  }

  .el-input__inner,
  .mx-input {
    background-color: transparent !important;
  }

  .add-owner {
    background-color: transparent;
  }

  .ownership-type-badge {
    display: inline-block;
    margin-left: 20px;
    padding: 5px 8px;
    font-size: 0.75rem;
    font-weight: 500;
    color: #ffffff;
    background-color: #322f35;
    border-radius: 4px;
    line-height: 14px;
    vertical-align: middle;
  }

  .owner-review {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: #f5f5f5;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 3px rgba(20, 213, 132, 0.25);
    }

    .owner-info {
      display: flex;
      align-items: center;

      .owner-percentage {
        font-size: 1.5rem;
        font-weight: bold;
        margin-right: 1rem;
      }

      .owner-name {
        font-size: 1.2rem;
        margin-right: 0.5rem;
      }

      .owner-label {
        font-size: 0.9rem;
        color: #666666;
      }
    }

    .error-icon {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-45%);
      & > i {
        font-size: 16px;
        color: red;
      }
    }

    .edit-icon {
      & > i {
        color: #333333;
      }
    }
    .remove-icon {
      position: absolute;
      right: -30px;
      top: 50%;
      transform: translateY(-45%);
      cursor: pointer;
      outline: none;

      &:focus {
        box-shadow: 0 0 0 3px rgba(20, 213, 132, 0.25);
        padding: 4px 8px;
        margin-right: -8px;
      }

      & > i {
        font-size: 16px;
        color: #333;
        outline: 2px solid transparent;
        outline-offset: 2px;
      }
    }
  }

  .ownership-notice {
    font-size: 0.9rem;
    color: #666666;
    margin-bottom: 1rem;
  }

  .add-owner-button {
    background-color: #000000;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;

    &:focus {
      box-shadow: 0px 1px 1px 4px rgba(20, 213, 132, 0.25) !important;
      background-color: #333333;
    }

    i {
      margin-right: 0.5rem;
    }
  }
}
</style>
