import { getColorType } from '../../helpers/uiTheme';

const productStatusUrl = productSlug => `/onboarding/spproducts/${productSlug}/status/`;

export default async function ({ store, axios, to, next }) {
  try {
    const url = productStatusUrl(to.params.spProductSlug);
    const facId = store.getters.getFacilitator.facId;
    const response = await axios({ url, method: 'POST', data: { facId } });

    if (response.data.status !== 'active') return next({ name: 'InitiateFailure' });
    const { branding, name } = response.data;
    let styles = document.body.style;
    localStorage.setItem('branding', JSON.stringify({ ...branding, name }));

    styles.setProperty('--body-background-color', branding.backgroundColor);
    styles.setProperty('--main-primary-color', branding.primaryColor);
    styles.setProperty('--main-secondary-color', branding.secondaryColor);
    styles.setProperty('--main-contrast-color', branding.contrastColor);
    styles.setProperty('--main-text-color', branding.textColor);
    styles.setProperty('--main-logo-url', branding.logoUrl);
    styles.setProperty('--main-navbar-background', branding.navbarBackgroundColor);
    styles.setProperty(
      '--titles-color',
      getColorType(branding.backgroundColor) === 'dark' ? '#ffffff' : '#000000'
    );

    next();
  } catch (error) {
    console.log(error);
    const defaultError = 'Server side error, please contact the support team.';
    const errorMessage = error.response ? error.response.data.message : defaultError;

    let logObject = {
      module: 'onboarding',
      error: errorMessage,
      details: {
        router: to.path,
        endpoint: `/onboarding/spproducts/${to.params.spProductSlug}/status/`,
        message: `Invalid product status.`
      }
    };
    console.log(JSON.stringify(logObject));
    store.commit('LOG_ERROR', errorMessage);
  }
}
