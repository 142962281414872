<template>
  <div @submit.prevent="next" id="confirm-profile">
    <div ref="divRef" class="d-flex flex-column align-items-center" v-scrollbar>
      <div class="page-title">
        <h1>Review your information</h1>
        <h2 class="sub-title my-2">
          Please check the information below. You will not be able to make changes once
          you submit
        </h2>
      </div>
      <div class="card-wrapper">
        <div class="card-header">
          <div class="circle">1</div>
          <h3 class="label">Business information</h3>
        </div>
        <div class="border-wrapper">
          <div class="card-content">
            <CompanyData
              ref="companyData"
              :identity="getIdentity"
              :ignore-individuals="true"
            />
          </div>
        </div>
      </div>
      <div class="card-wrapper">
        <div class="card-header">
          <div class="circle">2</div>
          <h3 class="label">
            {{
              getIdentity.type === 'government-public-corp'
                ? 'Contact information'
                : 'Owner information'
            }}
          </h3>
        </div>
        <div class="border-wrapper">
          <div class="card-content">
            <div
              v-for="(person, index) in formattedIndividuals"
              :key="index"
              class="owner-section"
            >
              <IndividualData
                ref="individualData"
                :individual="person"
                :type="getIdentity.type"
                :isContact="getIdentity.type === 'government-public-corp'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-wrapper">
        <div class="card-header">
          <div class="circle">3</div>
          <h3 class="label">Bank account(s)</h3>
        </div>
        <div class="border-wrapper">
          <div class="card-content">
            <BankAccountData
              ref="bankAccountData"
              :bankAccounts="bankAccounts"
              :identityType="getIdentity.type"
            />
          </div>
        </div>
      </div>
      <div class="card-wrapper">
        <div class="card-header">
          <div class="circle">4</div>
          <h3 class="label">Fees disclosure & acknowledgement</h3>
        </div>
        <div class="border-wrapper">
          <div class="card-content">
            <FeesData ref="feesData" :platformFees="getFees" />
            <div class="d-flex gap-2 mt-3">
              <input
                class="onboarding-input"
                :checked="confirm"
                @change="handleConfirmChange"
                type="checkbox"
                name="confirm"
                ref="checkbox"
              />
              <label for="confirm" class="ml-1" @click="handleConfirmChange">
                I hereby certify that the information provided in this application is
                complete and correct to the best of my knowledge.
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end w-100">
        <button
          ref="submitButton"
          class="btn continue-button"
          :disabled="!confirm || isNavigating"
          @click="next"
        >
          {{ isNavigating ? 'Processing...' : 'Submit' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BankAccountData from '../components/BankAccountData';
import CompanyData from '../components/CompanyData';
import FeesData from '../components/FeesData';
import IndividualData from '../components/IndividualData';
import { formatDBDateToPrettyDate } from '@/helpers/date';
import swal from 'sweetalert2';
import { OWNERSHIP_TYPES } from '@/helpers/constants';

export default {
  components: { CompanyData, BankAccountData, FeesData, IndividualData },
  data() {
    return {
      bankAccounts: [],
      confirm: false,
      isNavigating: false
    };
  },
  computed: {
    ...mapGetters(['getIdentity', 'getPayout', 'getFees', 'getSaveAndExit']),
    getIndividuals() {
      if (this.getIdentity.type === 'government-public-corp') {
        return this.getIdentity.contacts || [];
      }
      return this.getIdentity.owners || [];
    },
    formattedIndividuals() {
      return this.getIndividuals.map(individual => ({
        ...individual,
        // birthDate: individual.birthDate ? formatDBDateToPrettyDate(individual.birthDate) : '',
        dateStarted: individual.dateStarted
          ? formatDBDateToPrettyDate(individual.dateStarted)
          : '',
        ssn: individual.ssn
          ? individual.ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
          : '',
        address:
          `${individual.streetNum || ''} ${individual.streetName || ''}`.trim() || 'N/A',
        // Only include ownershipPercentage for owners, not contacts
        ...(this.getIdentity.type !== 'government-public-corp' && {
          ownershipPercentage: individual.ownershipPercentage
        })
      }));
    }
  },
  async created() {
    const loader = this.$loading.show();
    try {
      await this.$store.dispatch('GET_IDENTITY');
      await this.$store.dispatch('GET_PAYOUT_ACCOUNT', true);
      await this.$store.dispatch('GET_FEES');
      this.bankAccounts = this.getPayout;
    } catch (error) {
      // return to get started page
      this.$router.push({ name: 'GetStarted', params: { _normalPush: true } }).catch(()=>{});
    } finally {
      loader.hide();
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleTabPress);
    this.$store.commit('MARK_STEP_COMPLETED', 4);

    this.$nextTick(() => {
      let attempts = 0;
      const interval = setInterval(() => {
        const firstEditButton = this.$refs.companyData?.$refs.editButton;
        if (firstEditButton && document.activeElement === firstEditButton) {
          clearInterval(interval);
          return;
        }
        if (firstEditButton) {
          firstEditButton.focus();
        } else if (attempts > 10) {
          clearInterval(interval);
        }
        attempts++;
      }, 100);
    });
    this.$refs.checkbox.addEventListener('keydown', this.handleCheckboxKeydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleTabPress);
    this.$refs.checkbox.removeEventListener('keydown', this.handleCheckboxKeydown);
  },
  watch: {
    getSaveAndExit(newValue) {
      if (newValue) {
        this.exit();
      }
    }
  },
  methods: {
    handleConfirmChange() {
      this.confirm = !this.confirm;
    },
    async validateAllData() {
      const errors = [];

      // Validate company data
      const companyValid = await this.$refs.companyData.validate();
      if (!companyValid) {
        errors.push({
          section: 'Business information',
          route: 'CompanyForm',
          message: 'Please review and correct your business information.'
        });
      }

      // Validate individual data
      if (
        this.getIdentity.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP &&
        this.formattedIndividuals.length !== 1
      ) {
        errors.push({
          section: 'Owner information',
          route: 'VerifyOwnership',
          message: 'Please review and correct the owner information.'
        });
      }

      const individualValidations = await Promise.all(
        this.$refs.individualData.map(ref => ref.validate())
      );

      const invalidIndividuals = individualValidations.filter(result => !result.isValid);

      if (invalidIndividuals.length > 0) {
        errors.push({
          section:
            this.getIdentity.type === 'government-public-corp'
              ? 'Contact information'
              : 'Owner information',
          route: 'VerifyOwnership',
          message: `Please review and correct the ${
            this.getIdentity.type === 'government-public-corp' ? 'contact' : 'owner'
          } information.`
        });
      }

      // Validate ownership percentage only for non-government types
      if (this.getIdentity.type !== 'government-public-corp') {
        const totalOwnership = this.getIndividuals.reduce(
          (sum, individual) => sum + (individual.ownershipPercentage || 0),
          0
        );
        if (totalOwnership < 75 || totalOwnership > 100) {
          errors.push({
            section: 'Owner information',
            route: 'VerifyOwnership',
            message: `Total ownership percentage should be between 75% and 100%. Current total: ${totalOwnership}%`
          });
        }
      }

      // Validate bank account data
      const bankAccountValid = await this.$refs.bankAccountData.validate();
      if (!bankAccountValid) {
        errors.push({
          section: 'Bank account(s)',
          route: 'BankForm',
          message: 'Please review and correct your bank account information.'
        });
      }

      return errors;
    },
    async next() {
      if (!this.confirm || this.isNavigating) return;

      this.isNavigating = true;
      try {
        const errors = await this.validateAllData();
        if (errors.length > 0) {
          await this.handleValidationErrors(errors);
          return;
        }

        this.$store.commit('MARK_STEP_COMPLETED', 4);
        await this.$router.push({ name: 'Checks', params: { ack: this.confirm, _normalPush: true } }).catch(()=>{});
      } catch (error) {
        console.error('Navigation failed:', error);
      } finally {
        this.isNavigating = false;
      }
    },
    async handleValidationErrors(errors) {
      if (errors.length === 0) return;

      const firstError = errors[0];
      const additionalErrorsCount = errors.length - 1;

      let errorMessage = firstError.message;
      if (additionalErrorsCount > 0) {
        errorMessage += `\n\nThere ${additionalErrorsCount === 1 ? 'is' : 'are'} ${additionalErrorsCount} additional error${additionalErrorsCount === 1 ? '' : 's'} that need to be addressed.`;
      }

      await swal
        .fire({
          title: 'Validation Error',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'Fix Issues',
          showCancelButton: true,
          cancelButtonText: 'Stay on this page'
        })
        .then(async result => {
          if (result.isConfirmed) {
            await this.$router.push({ name: firstError.route, params: { _normalPush: true } }).catch(()=>{});
          }
        });
    },
    async back() {
      if (this.isNavigating) return;

      this.isNavigating = true;
      try {
        await this.$router.push({ name: 'BankForm', params: { _normalPush: true } }).catch(()=>{});
      } catch (error) {
        console.error('Navigation failed:', error);
        this.isNavigating = false;
      }
    },
    exit() {
      this.$router.push({ name: 'ApplicationSaved', params: { _normalPush: true } }).catch(()=>{});
    },
    handleTabPress(event) {
      if (event.key !== 'Tab') {
        return;
      }
      const editButtons = [
        this.$refs.companyData?.$refs.editButton,
        ...(this.$refs.individualData
          ? this.$refs.individualData
              .map(ref => ref.$refs.editButton)
              .filter(btn => btn !== undefined)
          : []),
        this.$refs.bankAccountData?.$refs.editButton,
        this.$refs.feesData?.$refs.editButton,
        this.$refs.checkbox,
        this.$refs.submitButton
      ].filter(btn => btn !== undefined);

      const currentIndex = editButtons.indexOf(document.activeElement);
      if (currentIndex === -1) return;

      let nextIndex = event.shiftKey
        ? (currentIndex - 1 + editButtons.length) % editButtons.length
        : (currentIndex + 1) % editButtons.length;

      if (
        editButtons[nextIndex] === this.$refs.submitButton &&
        this.$refs.submitButton.disabled
      ) {
        nextIndex = event.shiftKey
          ? (nextIndex - 1 + editButtons.length) % editButtons.length
          : (nextIndex + 1) % editButtons.length;
      }

      editButtons[nextIndex].focus();
      const element = editButtons[nextIndex];
      element.style.scrollMarginTop = '100px';
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });

      event.preventDefault();
    },
    handleCheckboxKeydown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.confirm = !this.confirm;
      }
    }
  }
};
</script>

<style lang="scss">
#confirm-profile {
  scroll-behavior: smooth;
  & > div {
    max-height: calc(100vh - 230px);
    position: relative;
    & > * {
      max-width: 480px;
      width: 100%;
    }
  }


  .card-wrapper {
    padding: 0 0 0 15px;
    position: relative;
  }

  .card-header {
    display: flex;
    align-items: center;
    padding: 0;
    background: inherit;
    border: none;
    margin-left: 15px;
    & h3 {
      margin: 0;
      margin-left: 15px;
    }
  }

  .card-content {
    position: relative;
    box-shadow:
      0 2px 4px rgba(0, 0, 0, 0.1),
      0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 15px;
  }

  .border-wrapper {
    border-left: 1px solid #14d584;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .circle {
    position: absolute;
    top: -3px;
    left: -1px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: #14d584;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-size: 20px;
    font-weight: 500;
  }

  .label {
    font-weight: bold;
  }

  .edit-button {
    position: absolute;
    bottom: 16px;
    right: 16px;
    background: #ece6f0;
    border: none;
    border-radius: 12px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    & i {
      font-size: 16px;
      color: #4a4a4a;
    }
    &:focus {
      outline: 2px solid #14d584 !important; // Green outline for focus
      box-shadow: 0 0 5px rgba(20, 213, 132, 0.5) !important;
    }
  }

  .onboarding-input {
    color: #14d584;
    border-color: #14d584;
  }

  .continue-button {
    background-color: #14d584;
    border-color: #14d584;
    color: #fff;
    z-index: 2;
  }

  .continue-button:disabled {
    background-color: #ccc;
    border-color: #ccc;
    &:hover {
      background-color: #aeaeae !important;
      border-color: #aeaeae !important;
    }
  }

  input[type='checkbox'] {
    &::before {
      box-shadow: inset 35px 1em #14d584 !important;
    }
    &:focus {
      outline: 2px solid #14d584 !important;
      box-shadow: 0 0 5px rgba(#14d584, 0.5) !important;
    }
  }

  .owner-section {
    & + .owner-section {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .ps {
    &::after {
      content: '';
      position: sticky;
      bottom: 0;
      width: 100%;
      min-height: 100px;
      margin-top: -100px;
      background: linear-gradient(to bottom, transparent, #fdfcfc);
      pointer-events: none;
    }
  }
}
</style>
